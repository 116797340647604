<template>
    <div>
        <!-- working <img :src="imgSrc" /> -->
        <component :is="imgObj" :id="route.params.avatarId" />
    </div>
</template>

<script>
// Get user avatar via URL. E.g. https://app.signon.my/avatar/{userId}
import { ref, inject, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import ImgAvatar from '@/components/ImgAvatar.vue'

export default {
    name: 'Avatar',
    setup () {
        const axios = inject('axios')
        const route = useRoute()

        const avatarUrl = ref(null) 
        const imgSrc = ref(null) 

        const imgObj = computed(() => {
            return ImgAvatar
        })

        return { 
            route, ImgAvatar, avatarUrl, imgSrc, imgObj
        }
    }
}
</script>

<style>

</style>